import React from "react";
import '../styles/global.css';
import section from '../styles/sections.module.css';
import {SmallSection} from './sections';
import flex from '../styles/flexbox.module.css';

export default function PageBanner({children, bgImg}){
    return(
        <>
            <section className={section.banner} style={{backgroundImage: `${bgImg ? `url(${bgImg})` : ''}`}}></section>
            <SmallSection className={section.light} flex={`${flex.container} ${flex.directionColumn}`}>
                {children}
            </SmallSection>
        </>
    )
}